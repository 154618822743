import React from "react";
import Layout from "../../components/Layout";
import NewsList from "../../components/NewsList";

export const NewsPageTemplate = () => {
  return (
    <section>
      <NewsList />
    </section>
  );
};

const NewsPage = () => {
  return (
    <Layout pageId="news" metadata={{ title: "News" }}>
      <NewsPageTemplate />
    </Layout>
  );
};

export default NewsPage;
